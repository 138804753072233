<template>
  <div class="flex justify-between items-center mt-3">
    <div class="text-sm">
      <el-select class="w-20 rounded-lg" size="small" v-model="limitProp" placeholder="Select Page">
        <el-option value="20">20</el-option>
        <el-option value="50">50</el-option>
        <el-option value="100">100</el-option>
      </el-select>

    </div>

    <div role="pagination" aria-label="pagination" class="el-pagination" v-if="prev || next">
      <button type="button" class="btn-prev" aria-disabled="true" :disabled="!prev" @click="handleCurrentChange(prev, currentPage-1)"><i class="el-icon el-icon-arrow-left"></i></button>
        <ul class="el-pager">
          <li class="number" aria-current="false" tabindex="0" v-if="prev && currentPage > 2" @click="handleCurrentChange(null, 1)">1</li>
          <li class="el-icon more btn-quicknext el-icon-more" v-if="prev && currentPage > 2" />
          <li class="number" aria-current="false" tabindex="0" v-if="currentPage > 1" @click="handleCurrentChange(prev, currentPage-1)">{{ currentPage - 1 }}</li>
          <li class="active number" aria-current="false" tabindex="0">{{ currentPage }}</li>
          <li class="number" aria-current="false" tabindex="0" v-if="next" @click="handleCurrentChange(next, currentPage+1)">{{ currentPage + 1 }}</li>
          <li class="el-icon more btn-quicknext el-icon-more" v-if="next"></li>
        </ul>
      <button type="button" class="btn-next" aria-disabled="false" :disabled="!next" @click="handleCurrentChange(next, currentPage+1)"><i class="el-icon el-icon-arrow-right"></i></button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 1,
    },
    next: {
      type: String,
      default: null,
    },
    prev: {
      type: String,
      default: null,
    }
  },

  data() {
    return {
      page: {
        startPage: null,
        toPage: null,
      },
    };
  },

  computed: {
    ...mapGetters(["selectCurrentCompany"]),
    displayCurrentPage: {
      get: function () {
        return this.currentPage;
      },
      set: function (modifiedCurrentPage) {
        this.handlePage();
        return modifiedCurrentPage;
      },
    },
    limitProp: {
      get() {
        return this.limit;
      },
      set(value) {
        this.$emit("update:limit", parseInt(value));
      },
    },
    totalPageProp: {
      get() {
        return this.totalPage;
      },
    },
  },

  created() {
    this.handlePage();
  },

  methods: {
    handleCurrentChange(cursor, page) {
      this.displayCurrentPage = page;
      this.$emit("update:cursor", cursor);
      this.$emit("update:currentPage", page);
    },

    handlePage() {
      this.page.startPage = (this.currentPage - 1) * this.limit + 1;
      if (this.currentPage == this.totalPage) this.page.toPage = this.totalItem;
      else this.page.toPage = this.currentPage * this.limit;
    },
  },

  watch: {
    currentPage() {
      this.handlePage();
    },
    totalItem() {
      this.handlePage();
    },
    limit() {
      this.handlePage();
    },
  },
};
</script>
