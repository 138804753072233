import formatDate from "@/plugins/formatDate.js";
import formatNumber from "@/plugins/formatNumber.js";
import InputMoney from "@/components/inputMoney.vue";
import CursorPaginate from "@/components/CursorPaginate.vue";
import { URL } from "@/api/invoice_summary";
import { mapGetters } from "vuex";

export default {
  components: {
    InputMoney,
    CursorPaginate,
  },
  data() {
    return {
      activeNames: ['1'],
      search: null,
      amount: {
        start: null,
        end: null,
      },
      unit_price: {
        start: null,
        end: null,
      },
      vat: null,
      vat_amount: {
        start: null,
        end: null,
      },
      amount_included_vat: {
        start: null,
        end: null,
      },
      issued_date: [],
      multipleSearch: {
        order_by: null,
        order_type: null,
        page: null,
        invoice__number: null,
        invoice__form: null,
        invoice__serial: null,
        invoice__seller_tax_code: null,
        invoice__seller_name: null,
        invoice__buyer_tax_code: null,
        invoice__buyer_name: null,
        invoice__issued_date: null,
        invoice__currency: null,
        description: null,
        unit: null,
        quantity: null,
        unit_price: null,
        amount: null,
        vat_amount: null,
        amount_included_vat: null,
        vat: null,
        is_no_tax: null,
      },
      columnStatus: {
        order_by: true,
        order_type: true,
        page: true,
        invoice__number: true,
        invoice__form: true,
        invoice__serial: true,
        invoice__seller_tax_code: true,
        invoice__seller_name: true,
        invoice__buyer_tax_code: true,
        invoice__buyer_name: true,
        invoice__currency: true,
        issued_date: true,
        description: true,
        unit: true,
        quantity: true,
        unit_price: true,
        amount: true,
        vat_amount: true,
        amount_included_vat: true,
        vat: true,
        is_no_tax: true,
      },
      errorPrice: {
        is_amount: false,
        is_unit_price: false,
        is_vat_amount: false,
        is_amount_included_vat: false,
      },
      is_disable_search: false,
      dataInvoices: [],
      paginate: {
        limit: 20,
        currentPage: 1,
        cursor: null,
        next: null,
        prev: null,
      },
      isRecordsExportable: false,
      isLoading: false,
      csv_processing_state: false,
    };
  },

  async created() {
    this.paginate = this.getSummaryPaginate;
    await this.getCSVProccessingStatus();
  },

  methods: {
    async getListSummary() {
      if (!this.is_disable_search) {
        this.isLoading = true;
        this.multipleSearch.cursor = this.paginate.cursor;
        this.multipleSearch.limit = this.paginate.limit;
        const result = await this.$request({
          url: URL.LIST_SUMMARY,
          params: this.multipleSearch,
        });
        const res = result.data;
        if (res.code == 200) {
          this.dataInvoices = res.data.data;
          this.dataPaginate = res.data;
          this.paginate = JSON.parse(JSON.stringify(this.paginate));
          this.paginate.prev = res.data.prev;
          this.paginate.next = res.data.next;
          this.$store.dispatch("setSummaryPaginate", this.paginate);
          this.isRecordsExportable = res.data.data.length > 0;
        }
        this.isLoading = false;
      }
    },

    handleLimitChange(limit) {
      this.paginate.limit = limit;
      this.paginate.currentPage = 1;
      this.paginate.cursor = null;
      this.paginate.next = null;
      this.paginate.prev = null;
      this.getListSummary();
    },

    handleCurrentPageChange($event) {
      this.paginate.currentPage = $event;
    },

    handleCursorChange($event) {
      this.paginate.cursor = $event;
      this.backToTop();
      this.getListSummary();
    },

    handleData() {
      if (this.amount.start && this.amount.end) {
        this.multipleSearch.amount = `${this.amount.start}__${this.amount.end}`;
        if (this.multipleSearch.amount == "0__0") {
          this.multipleSearch.amount = null;
        }
      } else {
        this.multipleSearch.amount = null;
      }

      if (this.unit_price.start && this.unit_price.end) {
        this.multipleSearch.unit_price = `${this.unit_price.start}__${this.unit_price.end}`;
        if (this.multipleSearch.unit_price == "0__0") {
          this.multipleSearch.unit_price = null;
        }
      } else {
        this.multipleSearch.unit_price = null;
      }

      if (this.vat_amount.start && this.vat_amount.end) {
        this.multipleSearch.vat_amount = `${this.vat_amount.start}__${this.vat_amount.end}`;
        if (this.multipleSearch.vat_amount == "0__0") {
          this.multipleSearch.vat_amount = null;
        }
      } else {
        this.multipleSearch.vat_amount = null;
      }

      if (this.amount_included_vat.start && this.amount_included_vat.end) {
        this.multipleSearch.amount_included_vat = `${this.amount_included_vat.start}__${this.amount_included_vat.end}`;
        if (this.multipleSearch.amount_included_vat == "0__0") {
          this.multipleSearch.amount_included_vat = null;
        }
      } else {
        this.multipleSearch.amount_included_vat = null;
      }

      if (this.issued_date == null) {
        this.multipleSearch.invoice__issued_date = null;
      } else if (this.issued_date.length > 0) {
        const startDate = this.formatDateApi(this.issued_date[0]);
        const endDate = this.formatDateApi(this.issued_date[1]);
        this.multipleSearch.invoice__issued_date = `${startDate}__${endDate}`;
      }

      this.multipleSearch.is_no_tax = null;
      if (this.vat == "KCT") {
        this.multipleSearch.vat = "0";
        this.multipleSearch.is_no_tax = "True";
      } else if (this.vat) {
        this.multipleSearch.vat = this.vat.replace("%", "");
      } else {
        this.multipleSearch.vat = null;
      }

      for (var key in this.multipleSearch) {
        if (this.multipleSearch[key] == "") {
          this.multipleSearch[key] = null;
        }
      }
    },

    checkMoneyRange() {
      this.errorPrice = {
        is_amount: false,
        is_unit_price: false,
        is_vat_amount: false,
        is_amount_included_vat: false,
      };

      if (!this.unit_price.start) {
        this.unit_price.start = "0";
      }
      if (!this.unit_price.end) {
        this.unit_price.end = "0";
      }
      if (parseInt(this.unit_price.start) > parseInt(this.unit_price.end)) {
        this.errorPrice.is_unit_price = true;
      }

      if (!this.amount.start) {
        this.amount.start = "0";
      }
      if (!this.amount.end) {
        this.amount.end = "0";
      }
      if (parseInt(this.amount.start) > parseInt(this.amount.end)) {
        this.errorPrice.is_amount = true;
      }

      if (!this.vat_amount.start) {
        this.vat_amount.start = "0";
      }
      if (!this.vat_amount.end) {
        this.vat_amount.end = "0";
      }
      if (parseInt(this.vat_amount.start) > parseInt(this.vat_amount.end)) {
        this.errorPrice.is_vat_amount = true;
      }

      if (!this.amount_included_vat.start) {
        this.amount_included_vat.start = "0";
      }
      if (!this.amount_included_vat.end) {
        this.amount_included_vat.end = "0";
      }
      if (
        parseInt(this.amount_included_vat.start) >
        parseInt(this.amount_included_vat.end)
      ) {
        this.errorPrice.is_amount_included_vat = true;
      }

      this.is_disable_search = false;
      for (var key in this.errorPrice) {
        if (this.errorPrice[key]) {
          this.is_disable_search = true;
        }
      }
    },

    backToTop() {
      if (this.$refs.scrollbar) {
        this.$refs.scrollbar.setScrollTop(0);
      }
    },

    fetchSearch() {
      this.paginate.currentPage = 1;
      this.paginate.cursor = null;
      this.paginate.next = null;
      this.paginate.prev = null;
      this.checkMoneyRange();
      this.handleData();
      this.getListSummary();
    },

    deleteAllSearch() {
      this.multipleSearch = {
        order_by: null,
        order_type: null,
        invoice__number: null,
        invoice__form: null,
        invoice__serial: null,
        invoice__seller_tax_code: null,
        invoice__seller_name: null,
        invoice__buyer_tax_code: null,
        invoice__buyer_name: null,
        invoice__issued_date: null,
        description: null,
        unit: null,
        quantity: null,
        unit_price: null,
        amount: null,
        vat_amount: null,
        amount_included_vat: null,
        vat: null,
      };
      this.amount = {
        start: null,
        end: null,
      };
      this.unit_price = {
        start: null,
        end: null,
      };
      this.vat = null;
      this.vat_amount = {
        start: null,
        end: null,
      };
      this.amount_included_vat = {
        start: null,
        end: null,
      };
      this.issued_date = [];
      this.paginate.currentPage = 1;
      this.paginate.cursor = null;
      this.paginate.next = null;
      this.paginate.prev = null;

      this.getListSummary();
    },

    sortChange(sortProps) {
      if (sortProps.prop) {
        this.multipleSearch.order_by = sortProps.prop;
        if (sortProps.prop.indexOf(".") != -1) {
          this.multipleSearch.order_by = sortProps.prop.replace(".", "__");
        }
      }
      if (sortProps.order)
        this.multipleSearch.order_type = sortProps.order.slice(0, 3);
      else this.multipleSearch.order_type = null;
      this.getListSummary();
    },

    formatDateApi(data) {
      var d = new Date(data);
      var datestring = `${d.getFullYear()}-
      ${("0" + (d.getMonth() + 1)).slice(-2)}-
      ${("0" + d.getDate()).slice(-2)}`;
      return datestring.replace(/\s+/g, "");
    },

    changeStatusColumn($event) {
      const lableName = $event.target.innerText.toString().toLowerCase();
      const tableObj = {
        "mẫu số": "invoice__form",
        "ký hiệu": "invoice__serial",
        "số hóa đơn": "invoice__number",
        "ngày ph": "issued_date",
        "bên bán": "invoice__seller_name",
        "mst bán": "invoice__seller_tax_code",
        "tên hh,dv": "description",
        "bên mua": "invoice__buyer_name",
        "mst mua": "invoice__buyer_tax_code",
        "tiền tệ": "invoice__currency",
        "đơn vị tính": "unit",
        "số lượng": "quantity",
        "đơn giá": "unit_price",
        "thành tiền": "amount",
        "tiền thuế": "vat_amount",
        "tổng tiền": "amount_included_vat",
        "thuế suất": "vat",
      };
      const columnName = tableObj[lableName];
      this.columnStatus[columnName] = !this.columnStatus[columnName];
    },

    indexMethod(index) {
      return index + 1 + (this.paginate.currentPage - 1) * this.paginate.limit;
    },

    clickRow(obj) {
      this.$router.push({
        name: "editInvoices",
        params: { invoiceId: obj.invoice.id },
      });
    },

    async getCSVProccessingStatus() {
      let res = await this.$request({
        url: URL.EXPORT_SUMMARY_STATUS,
      });
      res = res.data
      if (res.code == 200) {
        this.csv_processing_state = res.data.csv_processing_state;

        clearInterval(this.idInterval);
        if (this.csv_processing_state && this.csv_processing_state.indexOf('%') != -1) {
          this.idInterval = setInterval(() => {
            this.getCSVProccessingStatus();
          }, 5000);
        }
      }
    },

    async requestExportCSV() {
        this.$swal({
          title: "Yêu cầu xuất tệp Excel",
          html: "Hệ thống có thể mất nhiều thời gian để xuất tệp, phụ thuộc vào số lượng kết quả <br><small class='font-bold'>*tối đa 100.000 bản ghi</small>",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3b82f6",
          cancelButtonColor: "#adadad",
          confirmButtonText: "Xác nhận",
          cancelButtonText: "Hủy!",
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.multipleSearch.page = this.paginate.currentPage;
            this.multipleSearch.limit = this.paginate.limit;
            await this.$request({
              url: URL.EXPORT_SUMMARY,
              method: 'POST',
              params: this.multipleSearch
            });
            this.getCSVProccessingStatus();
          }
        });
    },

    async exportCSV() {
      const result = await this.$request({
        url: URL.EXPORT_SUMMARY,
        method: 'GET',
        responseType: "blob",
        params: this.multipleSearch
      });

      const fileName = `transaction-${new Date().toISOString().split('T')[0]}.xlsx`
      this.execDownloadFile(result.data, fileName);
    },

    execDownloadFile(blob, fileName) {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    },

    handleRequestSyncDate(dateRange) {
      if (this.is_itaxadmin) return;
      const start = new Date(dateRange[0]);
      const end = new Date(dateRange[1]);
      const ONE_YEAR = 366 * 24 * 3600 * 1000
      if (end.getTime() - start.getTime() > ONE_YEAR) {
        start.setTime(end.getTime() - ONE_YEAR);
        this.issued_date = [start, end];
      }
    },
    disabledDate(time) {
      return time.getTime() > Date.now();
    },
    formatNumber,
    formatDate,
  },

  computed: {
    ...mapGetters([
      "getSummaryPaginate",
      "getUserIndexRole",
    ]),
    total_unit_price() {
      return this.unit_price.start + this.unit_price.end;
    },
    total_amount() {
      return this.amount.start + this.amount.end;
    },
    total_amount_included_vat() {
      return this.amount_included_vat.start + this.amount_included_vat.end;
    },
    total_vat_amount() {
      return this.vat_amount.start + this.vat_amount.end;
    },
    is_itaxadmin() {
      return this.getUserIndexRole >= 5;
    }
  },

  watch: {
    total_unit_price() {
      this.checkMoneyRange();
    },
    total_amount() {
      this.checkMoneyRange();
    },
    total_amount_included_vat() {
      this.checkMoneyRange();
    },
    total_vat_amount() {
      this.checkMoneyRange();
    },
    multipleSearch: {
      deep: true,
      handler(newVal) {
        if (newVal.invoice__buyer_tax_code) {
          this.multipleSearch.invoice__buyer_tax_code = newVal.invoice__buyer_tax_code.replace(/[^\d-]+/, '');
        }
        if (newVal.invoice__seller_tax_code) {
          this.multipleSearch.invoice__seller_tax_code = newVal.invoice__seller_tax_code.replace(/[^\d-]+/, '');
        }
      }
    },
  },
  beforeRouteLeave() {
    clearInterval(this.idInterval);
  },
};
